/**
 * = Datepickers
 */

 .datepicker {

    .datepicker-cell.selected,
    .datepicker-cell.selected:hover {
      background: $primary;
    }
  
  }
  